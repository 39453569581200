
import { motion } from 'framer-motion';
import { images } from '../../constants';
import './About.scss'
import { AppWrap, MotionWrap } from '../../wrapper';


const abouts = [
  { title: 'DevOps Enthusiast', description: "I believe having efficient and reliable pipelines are fundamental in projects of any scale. To enhance the work of developers and operations is whati aim to do with my work.", imgUrl: images.cicd },
  { title: 'Teamwork makes the Dream work', description: "A team is a group of people coming together to achieve what they cannot do individually. Enhancing your teammates' strengths while adding your own twist, gives me more fulfillment than any individual achievement.", imgUrl: images.team },
  { title: 'If you enjoy what you do, you will never work a day in you life.', description: 'Wise words from someone I am not familiar with. Passion puts a smile on my face even in the toughest of times. The reason why I get out of bed in the morning: To make a difference with my work, and improve everyday.', imgUrl: images.heart },
  { title: 'Football Obsessed', description: 'Most of my off-time is spent either watching, playing, or talking about Football. To me, Football is more than just a sport, and without it, I would not be the person I am today.', imgUrl: images.guys },
]

const About = () => {

  return (
    <>
      <h2 className="head-text">A <span>Little Bit about</span> <br />  <span>Me</span></h2>

      <div className="app__profiles">
        {abouts.map((about, index) => (
          <motion.div
            whileInView={{ opacity: 1 }}
            whileHover={{ scale: 1.1 }}
            transition={{ duration: 0.5, type: 'tween' }}
            className="app__profile-item"
            key={about.title + index}
          >
            <img src={about.imgUrl} alt={about.title} />
            <h2 className="bold-text" style={{ marginTop: 20 }}>{about.title}</h2>
            <p className="p-text" style={{ marginTop: 10 }}>{about.description}</p>
          </motion.div>
        ))}
      </div>
    </>
  );
};
export default AppWrap(
  MotionWrap(About, 'app__about'),
  'about',
  'app__whitebg',
);