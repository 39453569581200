import React from 'react';
import { BsLinkedin } from 'react-icons/bs';
import { GoMarkGithub } from 'react-icons/go';

const SocialMedia = () => {
  return (
    <div className='app__social'>
        
        <div>
            <a href="https://www.linkedin.com/in/juliantanntu/" target="_blank" rel="noreferrer">

            <BsLinkedin />
            </a>
            
        </div>
        
        <div>
        <a href="https://github.com/jiantang99">

            <GoMarkGithub />

        </a>
        </div>
        

    </div>
  )
}

export default SocialMedia